export default [
  {
    label: 'Type',
    field: 'transactionType',
  },
  {
    label: 'Currency ',
    field: 'currency',
  },
  {
    label: 'Crypto Coin',
    field: 'cryptoCoin',
  },
  {
    label: 'Fee currency',
    field: 'feeCurrency',
  },
  {
    label: 'Fee percent',
    field: 'feePercentage',
  },
  {
    label: 'Minimal fee amount',
    field: 'minFee',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
